.spacedItems {
    margin-right: 16px;
  }

.menuButton {
    margin-right: 16px
}

.logo {
    height: 50px;
    width: auto;
}
.title {
   flex-grow: 1;
}

.menuItems {
    display: flex,
}

.gameToggleContainer {
    padding-top: 5px;
    width: 500px;
    height: 40px;
    float: right;
    display: table;
    table-layout: fixed;
}

.active {
    font-weight: bold;
    background-color: #043494;
    color: white;
}
  
.inactive {
    background-color: #e3ecf5;
}

.toggle {
    text-decoration: none;
    border-radius: 15px 15px 0 0;
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    cursor: pointer;
}

.gameIcon {
    width: 40px;
    border: 1px solid white;
}

.underlined {
    text-decoration: underline;
}

.styledDiv {
    flex-grow: 1;
 }