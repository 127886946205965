.textField {
    width: 100%;
}

.formControl {
    width: 100%;
    margin-top: 16px;
    text-align: left;
}

.disabilitySpace {
    margin-top: 10px;
}

.card {
    min-width: 275;
}

.submitButton {
    float: center;
    padding: 0.75rem 3rem;
}

.submitButtonSelfSignUp {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.submitButtonSelfSignUp:hover {
    background: #9c1169;
}


.gameLogo {
    width: 10rem;
    cursor: pointer;
}

.title {
    font-weight: bold;
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 0;
}

.subTitle {
    font-weight: bold;
    font-size: 16px;
}

.privacyPolicyText {
    font-size: 14px;
}